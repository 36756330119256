<template>
  <div id="update-shop-timings">
    <div class="p-2 flex flex-centered">
      <vx-col>
        <h3 class="font-bold text-2xl">{{ $t('ShopTimings') }}</h3>
        <div class="p-2"></div>

        <table class="support-timings-table">
          <tr>
            <th></th>
            <th></th>
            <th colspan="2" class="text-center">Shop Open Close</th>
            <th colspan="2" class="text-center">Lunch Break</th>
          </tr>
          <tr>
            <th>{{ $t('Holidays') }}</th>
            <th></th>
            <th>{{ $t('StartTime') }}</th>
            <th>{{ $t('CloseTime') }}</th>
            <th>{{ $t('StartTime') }}</th>
            <th>{{ $t('CloseTime') }}</th>
          </tr>
          <tr v-for="(shop, index) in shopTimings">
            <td>
              <vs-checkbox v-model="shop.weekend"></vs-checkbox>
            </td>
            <td>
              <p>{{ shop.day }}</p>
            </td>
            <td>
              <vue-timepicker
                v-model="shop.start_at"
                :minute-interval="5"
                hide-clear-button
                format="HH:mm"
                :drop-direction="getDropdownDirection(shop)"
                :disabled="shop.weekend"></vue-timepicker>
            </td>
            <td>
              <vue-timepicker
                v-model="shop.end_at"
                :minute-interval="5"
                hide-clear-button
                format="HH:mm"
                :drop-direction="getDropdownDirection(shop)"
                :disabled="shop.weekend"></vue-timepicker>
            </td>
            <td>
              <vue-timepicker
                v-model="shop.l_start_at"
                :minute-interval="5"
                hide-clear-button
                format="HH:mm"
                :drop-direction="getDropdownDirection(shop)"
                :disabled="shop.weekend"></vue-timepicker>
            </td>
            <td>
              <vue-timepicker
                v-model="shop.l_end_at"
                :minute-interval="5"
                hide-clear-button
                format="HH:mm"
                :drop-direction="getDropdownDirection(shop)"
                :disabled="shop.weekend"></vue-timepicker>
            </td>
          </tr>
        </table>


        <div class="vs-row flex justify-center mt-3">
          <vs-button class="ml-2 mt-3" color="primary" @click="updateShopTimings">{{ $t('Update') }}</vs-button>
          <vs-button class="ml-4 mt-3" color="danger" @click="$emit('close')">{{ $t('Close') }}</vs-button>
        </div>
      </vx-col>
    </div>
  </div>
</template>

<script>
import Logo from '@/layouts/components/Logo'
import VxModal from "@/layouts/components/custom/VxModal";
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  name: 'UpdateShopTimings',
  components: {
    VxModal,
    Logo,
    VueTimepicker,
  },
  data() {
    return {
      showShopTimings: false,
      shopTimings: [
        {
          day: 'Montag',
          'end_at': null,
          'start_at': null,
          'l_end_at': null,
          'l_start_at': null,
          'weekend': false,
        },
        {
          day: 'Dienstag',
          'end_at': null,
          'start_at': null,
          'l_end_at': null,
          'l_start_at': null,
          'weekend': false,
        },
        {
          day: 'Mittwoch',
          'end_at': null,
          'start_at': null,
          'l_end_at': null,
          'l_start_at': null,
          'weekend': false,
        },
        {
          day: 'Donnerstag',
          'end_at': null,
          'start_at': null,
          'l_end_at': null,
          'l_start_at': null,
          'weekend': false,
        },
        {
          day: 'Freitag',
          'end_at': null,
          'start_at': null,
          'l_end_at': null,
          'l_start_at': null,
          'weekend': false,
        },
        {
          day: 'Samstag',
          'end_at': null,
          'start_at': null,
          'l_end_at': null,
          'l_start_at': null,
          'weekend': true,
        },
        {
          day: 'Sonntag',
          'end_at': null,
          'start_at': null,
          'l_end_at': null,
          'l_start_at': null,
          'weekend': true,
        },
      ],
    }
  },
  props: {
    customerId: {
      default: null,
      type: String | Number,
    },
    propShopTimings: {
      default: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  mounted() {
    this.syncShopTimings()
    try {
      this.homeData = JSON.parse(localStorage.getItem('home'))
    } catch (e) {

    }

  },
  methods: {
    syncShopTimings() {
      if(this.propShopTimings) {
        try {
          this.shopTimings = JSON.parse(this.propShopTimings)
        } catch (e) {
          this.shopTimings = this.propShopTimings
        }
      } else {
        try {
          this.shopTimings = JSON.parse(this.activeUserInfo.shop_open_timings)
        } catch (e) {
          this.shopTimings = this.activeUserInfo.shop_open_timings
        }
      }


      for (let i = 0; i < this.shopTimings.length; i++) {
        if(this.shopTimings[i].day == 'Monday') {
          this.shopTimings[i].day = 'Montag'
        }
        if(this.shopTimings[i].day == 'Tuesday') {
          this.shopTimings[i].day = 'Dienstag'
        }
        if(this.shopTimings[i].day == 'Wednesday') {
          this.shopTimings[i].day = 'Mittwoch'
        }
        if(this.shopTimings[i].day == 'Thursday') {
          this.shopTimings[i].day = 'Donnerstag'
        }
        if(this.shopTimings[i].day == 'Friday') {
          this.shopTimings[i].day = 'Freitag'
        }
        if(this.shopTimings[i].day == 'Saturday') {
          this.shopTimings[i].day = 'Samstag'
        }
        if(this.shopTimings[i].day == 'Sunday') {
          this.shopTimings[i].day = 'Sonntag'
        }
      }

    },
    updateShopTimings() {
      for (let i = 0; i < this.shopTimings.length; i++) {
        if (this.shopTimings[i].start_at && typeof (this.shopTimings[i].start_at) == 'object') {
          this.shopTimings[i].start_at = `${this.shopTimings[i].start_at.HH}:${this.shopTimings[i].start_at.mm}`
        }
        if (this.shopTimings[i].end_at && typeof (this.shopTimings[i].end_at) == 'object') {
          this.shopTimings[i].end_at = `${this.shopTimings[i].end_at.HH}:${this.shopTimings[i].end_at.mm}`
        }
        if (this.shopTimings[i].l_start_at && typeof (this.shopTimings[i].l_start_at) == 'object') {
          this.shopTimings[i].l_start_at = `${this.shopTimings[i].l_start_at.HH}:${this.shopTimings[i].l_start_at.mm}`
        }
        if (this.shopTimings[i].l_end_at && typeof (this.shopTimings[i].l_end_at) == 'object') {
          this.shopTimings[i].l_end_at = `${this.shopTimings[i].l_end_at.HH}:${this.shopTimings[i].l_end_at.mm}`
        }
      }

      let payload = {
        // 'customer_id': this.activeUserInfo.customer_id,
        'shop_open_timings': JSON.stringify(this.shopTimings),
      }
      if(this.customerId) {
        payload['customer_id'] = this.customerId
      }
      this.$vs.loading()

      this.$store.dispatch('auth/updateShopTimings', payload).then((data) => {
        this.$vs.loading.close()
        this.$emit('close')
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
      })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    getDropdownDirection(shop) {
      if (shop.day == 'Saturday' || shop.day == 'Sunday') {
        return 'up'
      } else {
        return 'down'
      }
    },
    stringToBoolean(string) {
      switch (string.toLowerCase().trim()) {
        case 'true':
        case 'yes':
        case '1':
          return true
        case 'false':
        case 'no':
        case '0':
        case null:
          return false
        default:
          return Boolean(string)
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.theme-dark {
  #update-shop-timings {
    color: white !important;
  }
}
#update-shop-timings {
  -webkit-font-smoothing: antialiased;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;


  .vx-card__title {
    font-size: 20px;
    font-weight: bold;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
  }

  //h3 {
  //  margin: 40px 0 0;
  //}

  ul {
    list-style-type: none;
    padding: 16px;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  .mobile {
    font-weight: bold;
  }

  a {
    color: #42b983;
  }

  .support-timings-table {
    border: none;

    tr {
      td {
        padding: 10px 8px;
      }
    }
  }
}

</style>
