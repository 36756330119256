<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'CustomerDetails'">
    </breadcrumb-base>

    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="$t('CustomerDetails')">
            <template slot="actions">
              <div class="vx-row">
                <vs-button
                  v-if="activeUserInfo.has_credit_and_balance"
                  @click="openAddCreditPage"
                  type="filled" color="warning">{{ $t('TopUp') }}
                </vs-button>
                <div class="p-3"></div>
                <vs-button
                  v-if="activeUserInfo.has_assign_product"
                  @click="openCustomerPAD"
                  type="filled">{{ $t('PAD') }}
                </vs-button>
                <div class="p-3"></div>
                <vs-button type="border"
                           @click="openCustomerEditPage">{{ $t('Edit') }}
                </vs-button>
                <div class="p-2"></div>
              </div>
            </template>
            <div class="vx-row" v-if="customer">
              <div class="vx-col md:w-1/3">
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Reference') }}</p>
                  <p class="customer-text">{{ customer.reference }}
                  </p>
                </div>
                <div class="mt-2">
                  <p class="customer-text-heading">{{ $t('Organisation') }}</p>
                  <p class="customer-text">
                    {{ customer.organisation_name | title }}
                  </p>
                  <p class="customer-text" style="font-size: 12px;">
                    {{ customer.organisation_first_name }} {{ customer.organisation_last_name }}
                  </p>
                </div>
                <div class="mt-2">
                  <p class="customer-text-heading">{{ $t('Name') }}</p>
                  <p class="customer-text">
                    <feather-icon icon="CircleIcon"
                                  :svgClasses="['fill-current h-6 w-6', textColor]"/>
                    {{ customer.name | title }}
                  </p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Username') }}</p>
                  <p class="customer-text">{{ customer.username }}</p>
                </div>
                <div class="mt-4" v-if="customer.organisation_type == 1">
                  <p class="customer-text-heading">{{ $t('Domain') }}</p>
                  <p class="customer-text">{{ customer.sub_domain }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('City') }}</p>
                  <p class="customer-text">{{ customer.city }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Mobile') }}</p>
                  <p class="customer-text">{{ customer.mobile }}</p>
                </div>
                <div class="mt-4" v-if="customer.mobile_1 || customer.mobile_2">
                  <p class="customer-text-heading">{{ $t('AlternativeMobiles') }}</p>
                  <p class="customer-text" v-if="customer.mobile_1">{{ customer.mobile_1 }}</p>
                  <p class="customer-text" v-if="customer.mobile_2">{{ customer.mobile_2 }}</p>
                </div>
              </div>
              <div class="vx-col md:w-1/3">
                <div class="mt-2">
                  <div class="vs-row vx-col">
                    <p class="customer-text-heading">{{ $t('Credit') }}</p>
                    <p class="customer-text">{{ customer.credit | germanNumberFormat }}</p>
                  </div>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('Balance') }}</p>
                  <p class="customer-text">{{ customer.balance | germanNumberFormat }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('DiscountSlab') }}</p>
                  <p class="customer-text">{{ customer.discount_plan }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('CustomerID') }}</p>
                  <p class="customer-text">{{ customer.customer_id }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('OrganisationType') }}</p>
                  <p class="customer-text">
                    {{ organisationType[customer.organisation_type] || "Seller" }}</p>
                </div>

                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('OverdueSales') }}</p>
                  <p class="customer-text">{{ customer.over_due_days }}</p>
                </div>
              </div>
              <div class="vx-col md:w-1/3">

                <div class="mt-2">
                  <p class="customer-text-heading">{{ $t('YesterdaySales') }}</p>
                  <p class="customer-text">{{ customer.yesterday_sales | germanNumberFormat }}</p>
                </div>
                <div class="mt-4">

                  <vx-tooltip>
                    <template slot="tip">
                      <p><strong>{{ customer.last_cash_paid_at | dateFormat }}</strong></p>
                    </template>
                    <p class="customer-text-heading">{{ $t('LastCashPaid') }}</p>
                    <p class="customer-text">{{ customer.last_cash_paid | germanNumberFormat }}</p>
                  </vx-tooltip>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('LastPurchase') }}</p>
                  <p class="customer-text">{{ customer.last_purchase | dateFormat }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('PostalCode') }}</p>
                  <p class="customer-text">{{ customer.postal_code }}</p>
                </div>
                <div class="mt-4">
                  <p class="customer-text-heading">{{ $t('ActivatedOn') }}</p>
                  <p class="customer-text">{{ customer.activated_on | onlyDateFormat }}</p>
                </div>
                <div class="mt-4" v-if="customer.remarks">
                  <p class="customer-text-heading">{{ $t('Remarks') }}</p>
                  <vs-alert class="remark-alert" :active="customer && customer.remarks"
                            :color="color" icon="new_releases">
                    <span>{{ customer.remarks }}</span>
                  </vs-alert>
                  <!--                  <p class="customer-text-heading">{{$t('Remarks')}}</p>-->
                  <!--                  <p class="py-3 px-2" :style="`background-color: ${color};`">{{customer.remarks}}</p>-->
                </div>
              </div>
            </div>

            <div class="justify-end mt-6 flex flex-wrap gap-y-4 space-x-4">
              <vs-spacer></vs-spacer>
              <vs-button
                v-if="activeUserInfo.has_credit_and_balance"
                @click="openCustomerAutoCredit"
                color="warning" type="filled">{{ $t('AutoCredit') }}
              </vs-button>
              <vs-button
                @click="showChangePassword = true"
                color="danger" type="filled">{{ $t('ChangePassword') }}
              </vs-button>
              <vs-button
                v-if="activeUserInfo.has_device"
                @click="openCustomerDevices"
                type="filled">{{ $t('Devices') }}
              </vs-button>
              <!--              <div class="p-3"></div>-->
              <!--              <vs-button @click="openInvoicePage" type="filled" >{{ $t('Invoice') }}-->
              <!--              </vs-button>-->
              <vs-button
                v-if="activeUserInfo.has_dynamic_products"
                @click="openDynamicDiscount" type="filled">{{ $t('DynamicDiscounts') }}
              </vs-button>
              <vs-button @click="showUpdateRemark = true" type="filled">{{ $t('UpdateRemark') }}
              </vs-button>
              <vs-button @click="showShopTimings = true" type="filled">{{ $t('UpdateShopTimings') }}
              </vs-button>
              <vs-button v-if="activeUserInfo.account_role == 1 && customer" @click="updateMaskDiscount" type="filled">
                {{ customer.mask_seller_discount ? $t('ShowDiscount') : $t('MaskDiscount') }}
              </vs-button>
            </div>


            <div class="flex flex-row">
              <div class="section-terminal px-4">
                <div class="vx-row mt-6 vx-card__title">
                  <h4 class="">{{ $t('TerminalStatus') }}</h4>
                </div>

                <div class="mt-6 w-full" v-if="customer">
                  <div class="vs-col" v-if="customer.has_mail_send == 0">
                    <vs-alert
                      color="warning"
                      class="py-2 "
                    >
                      <p style="margin-bottom: 10px; color: rgb(var(--vs-danger))">
                        {{ $t('TerminalInActive') }}
                      </p>
                      <vs-button
                        class=""
                        @click="showTerminalLimit = true"
                        color="danger" type="border">{{ $t('ActivateTerminal') }}
                      </vs-button>
                    </vs-alert>
                  </div>
                  <vs-alert class="w-full" v-else color="success" icon="check_circle">
                    {{ $t('TerminalActivated') }} - <span><strong>{{
                      customer.terminal_id
                    }}</strong></span>
                  </vs-alert>
                  <!--                <p v-else style="color: rgb(var(&#45;&#45;vs-success))">-->
                  <!--                  {{ $t('TerminalActivated') }} - <span><strong>{{ customer.terminal_id }}</strong></span>-->
                  <!--                </p>-->
                  <!--                <vs-button class="mx-4" @click="activateTerminal" type="filled" >{{ $t('SendTerminalDetails') }}-->
                  <!--                </vs-button>-->
                </div>
              </div>
              <div class="section-terminal px-4" v-if="customer && customer.organisation_type == 2">
                <div class="vx-row mt-6 vx-card__title">
                  <h4 class="">{{ $t('UserStatus') }}</h4>
                </div>

                <div class="vx-row mt-6 w-full" v-if="customer">
                  <div class="vs-col" v-if="!customer.is_activate">
                    <vs-alert
                      color="warning"
                      class="py-2 "
                    >
                      <p style="margin-bottom: 10px; color: rgb(var(--vs-danger))">
                        {{ $t('Deactivate') }}
                      </p>
                      <vs-button
                        class=""
                        @click="activateUser"
                        color="danger" type="border">{{ $t('Activate') }}
                      </vs-button>
                    </vs-alert>
                  </div>
                  <div class="vs-col" v-if="customer.is_activate">
                    <vs-alert
                      color="success"
                      class="py-2 "
                    >
                      <p style="margin-bottom: 10px; color: rgb(var(--vs-success))">
                        {{ $t('Active') }}
                      </p>
                      <vs-button
                        class=""
                        @click="activateUser"
                        color="success" type="border">{{ $t('Deactivate') }}
                      </vs-button>
                    </vs-alert>
                  </div>
                </div>
              </div>
              <div class="section-terminal px-4 ml-4"
                   v-if="customer && activeUserInfo.account_role == 1">
                <div class="vx-row mt-6 vx-card__title">
                  <h4 class="">{{ $t('otp') }}</h4>
                </div>

                <div class="vx-row mt-6 w-full" v-if="customer">
                  <div class="vs-col" v-if="customer.enable_otp == 1">
                    <vs-alert
                      color="warning"
                      class="py-2 "
                    >
                      <vs-button
                        class=""
                        @click="updateOtp(0)"
                        color="danger" type="border">{{ $t('disableLoginOTP') }}
                      </vs-button>
                    </vs-alert>
                  </div>
                  <div class="vs-col" v-if="customer.enable_otp == 0">
                    <vs-alert
                      color="success"
                      class="py-2 "
                    >
                      <vs-button
                        class=""
                        @click="updateOtp(1)"
                        color="success" type="border">{{ $t('enableLoginOTP') }}
                      </vs-button>
                    </vs-alert>
                  </div>
                </div>
              </div>
            </div>

            <div class="vs-row flex py-2 mt-4" v-if="customer.organisation_type == 2 && customer">
              <label for="toggle" class="text-gray-700">
                <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input type="checkbox"
                         v-model="shopDetailsToPrint"
                         @change="updateCustomerShopDetailsPrint"
                         name="toggle" id="toggle"
                         class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                  <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                </div>
                <span>{{ $t('ShowShopDetailsOnPrint') }}</span>
              </label>
            </div>


            <div class="flex-col flex py-2 space-y-3 mt-4 px-4 mb-8" v-if="customer.organisation_type == 2 && customer">

              <div class="mt-6 vx-card__title">
                <h4 class="">{{ $t('API') }}</h4>
              </div>
              <div class="space-y-3">
                <p v-if="customer.api_user_key">
                  USER: {{ customer.api_user_key }}
                </p>
                <div v-if="customerAPIKey"
                     @click="copyToClipboard(customerAPIKey)"
                     class="border cursor-pointer border-2 flex flex-row justify-between border-primary px-2 py-4 rounded-md font-medium">
                  <p class="">
                    {{ customerAPIKey }}
                  </p>
                  <p class="text-primary font-bold flex items-center space-x-2 justify-center">
                    <span>{{ $t("Copy") }}</span>
                    <feather-icon icon="CopyIcon" svgClasses="h-6 w-6" class="mr-1" />
                  </p>
                </div>

                <div class="flex flex-row space-x-4">
                  <vs-button
                    v-if="customer.enable_api_access === 0"
                    class=""
                    @click="updateAPIStatus(false)"
                    color="success" >{{ $t('EnableAPI') }}
                  </vs-button>
                  <vs-button
                    v-if="customer.enable_api_access === 1"
                    class=""
                    @click="updateAPIStatus(true)"
                    color="warning" >{{ $t('ResetAPIKey') }}
                  </vs-button>
                  <vs-button
                    v-if="customer.enable_api_access === 1"
                    class=""
                    @click="updateAPIStatus(false)"
                    color="danger" >{{ $t('DisableAPI') }}
                  </vs-button>
                </div>
              </div>
            </div>
            <div class="flex-col flex py-2 space-y-3 mt-4 px-4 mb-8" v-if="customer.organisation_type == 2 && customer">

              <div class="mt-6 vx-card__title">
                <h4 class="">{{ $t('FaceValues') }}</h4>
              </div>
              <div class="space-y-3">
                <div class="flex flex-row space-x-4">
                  <vs-button
                    v-if="customer.has_face_value == 0"
                    class=""
                    @click="updateFaceValueStatus()"
                    color="success" >{{ $t('EnableFaceValues') }}
                  </vs-button>
                  <vs-button
                    v-if="customer.has_face_value == 1"
                    class=""
                    @click="updateFaceValueStatus()"
                    color="danger" >{{ $t('DisableFaceValues') }}
                  </vs-button>
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </transition>

    <vx-modal v-if="showTerminalLimit" @close="showTerminalLimit = false"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <vx-col>
            <h3>{{ $t('TerminalLimit') }}</h3>
            <div class="p-2"></div>
            <vs-input class="w-full" :label="$t('Limit')" v-model="terminalLimit" type="number"/>

            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="activateTerminal()">
                {{ $t('Activate') }}
              </vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showTerminalLimit = false">
                {{ $t('Close') }}
              </vs-button>
            </div>
          </vx-col>
        </div>
      </template>
    </vx-modal>

    <vx-modal v-if="showUpdateRemark" @close="showUpdateRemark = false"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <vx-col>
            <h3>Update Remark</h3>
            <div class="p-2"></div>
            <p v-if="customer.remarks">{{ customer.remarks }}</p>
            <div class="p-2"></div>
            <vs-textarea :label="$t('Remarks')"
                         v-model="remarks"/>

            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="updateRemark()">{{
                  $t('Update')
                }}
              </vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showUpdateRemark = false">
                {{ $t('Close') }}
              </vs-button>
            </div>
          </vx-col>
        </div>
      </template>
    </vx-modal>

    <vx-modal v-if="showChangePassword" @close="showChangePassword = false"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-6 flex flex-centered">
          <vx-col>
            <h3><strong>Change Password</strong></h3>
            <div class="w-full py-4 vs-row">
              <vs-input
                autofocus class="w-full py-"
                size="large" type="password" v-validate="'required'"
                validate-on="change"
                @input="passCheck"
                :label-placeholder="$t('Password')" v-model="newPassword"/>

              <div class="vs-col">
                <p class="text-sm pass-check-item"
                   :class="[passStrengthValue.caps ? 'text-success' : 'text-gray']">
                  {{ $t('UppercaseLetter') }}</p>
                <p class="text-sm pass-check-item"
                   :class="[passStrengthValue.small ? 'text-success' : 'text-gray']">
                  {{ $t('LowercaseLetter') }}</p>
                <p class="text-sm pass-check-item"
                   :class="[passStrengthValue.numbers ? 'text-success' : 'text-gray']">
                  {{ $t('Number') }}</p>
                <p class="text-sm pass-check-item"
                   :class="[passStrengthValue.special ? 'text-success' : 'text-gray']">
                  {{ $t('OneSymbol') }}</p>
                <p class="text-sm pass-check-item"
                   :class="[passStrengthValue.length ? 'text-success' : 'text-gray']">
                  {{ $t('EightCharacters') }}</p>
              </div>

            </div>
            <div class="vs-row">
              <vs-input class="w-full py-4" size="large" type="password" v-validate="'required'"
                        validate-on="change" :label-placeholder="$t('ConfirmPassword')"
                        v-model="newConfirmPassword"/>

            </div>
            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="changePassword()">
                {{ $t('ChangePassword') }}
              </vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="showChangePassword = false">
                {{ $t('Close') }}
              </vs-button>
            </div>
          </vx-col>

        </div>
      </template>
      <template slot="footer"></template>
    </vx-modal>


    <vx-modal v-if="showShopTimings" @close="showShopTimings = false"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <update-shop-timings @close="showShopTimings = false" :customer-id="customerId"
                             :prop-shop-timings="customer.shop_open_timings"></update-shop-timings>
      </template>
    </vx-modal>

  </div>
</template>

<script>
import copy from 'copy-to-clipboard';
import VxModal from '@/layouts/components/custom/VxModal'
import UpdateShopTimings from "@/views/components/UpdateShopTimings";

export default {
  name: 'DetailCustomer',
  components: {
    UpdateShopTimings,
    VxModal,
  },
  props: {
    customerId: {
      type: String | Number,
    },
  },
  computed: {

    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    textColor() {
      if (this.customer.flag == 0) {
        return 'text-danger'
      }
      if (this.customer.flag == 1) {
        return 'text-success'
      }
      if (this.customer.flag == 2) {
        return 'text-warning'
      }
      return this.customer.flag ? '' : 'text-grey'
    },
    color() {
      if (this.customer.flag == 0) {
        return 'danger'
      }
      if (this.customer.flag == 1) {
        return 'success'
      }
      if (this.customer.flag == 2) {
        return 'warning'
      }
      return ''
    },
  },
  data() {
    return {
      customerAPIKey: "",
      shopDetailsToPrint: true,
      showShopTimings: false,
      terminalLimit: '1000',
      showTerminalLimit: false,
      remarks: '',
      showUpdateRemark: false,
      customer: null,
      newPassword: '',
      newConfirmPassword: '',
      showChangePassword: false,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Customer', i18n: 'Customer', url: '/customers/view'},
        {title: 'Customer Details', i18n: 'CustomerDetails', active: true},
      ],
      organisationType: ['Super Admin', 'Reseller', 'Seller', 'Seller'],

      passStrengthValue: {
        'caps': false,
        'length': false,
        'special': false,
        'numbers': false,
        'small': false,
      },
    }
  },
  methods: {
    copyToClipboard(text) {
      copy(text);
    },
    activateTerminal() {
      if (this.terminalLimit == '' || isNaN(this.terminalLimit)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please fill terminal limit',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      this.$vs.loading()
      this.$store.dispatch('customer/activateTerminalId',
        {customerId: this.customerId, terminalLimit: this.terminalLimit})
        .then((data) => {
          this.showTerminalLimit = false;
          this.terminalLimit = ''
          this.$vs.loading.close()
          if (data.message_type == 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: `${data.terminal_id} ${data.message}`,
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    updateOtp(otp) {
      this.$vs.loading()
      this.$store.dispatch('customer/updateOtpStatus',
        {customerId: this.customerId, otp: otp})
        .then((data) => {
          this.$vs.loading.close()
          this.fetchCustomer()
          if (data.message_type == 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: `${data.message}`,
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    passCheck(value) {
      let caps = false
      let numbers = false
      let small = false
      let special = false
      for (let index = 0; index < this.newPassword.length; index++) {
        const char = this.newPassword.charCodeAt(index)
        if (char >= 65 && char <= 90) {
          caps = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if (char >= 97 && char <= 122) {
          small = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if ([33, 35, 36, 37, 38, 64, 42, 63, "33", "35", "36", "37", "38", "64", "42", "63"].includes(char)) {
          special = true
        }
      }
      this.passStrengthValue.caps = caps
      this.passStrengthValue.numbers = numbers
      this.passStrengthValue.small = small
      this.passStrengthValue.special = special
      if (this.newPassword.length < 8) {
        this.passStrengthValue.length = false
      } else {
        this.passStrengthValue.length = true
      }
      const obj = {...this.passStrengthValue}
      this.passStrengthValue = {}
      this.passStrengthValue = {...obj}
    },
    openCustomerAutoCredit() {
      this.$router.push({
        name: 'customer-credit-auto',
        params: {customerId: this.customer.customer_id}
      })
    },
    openCustomerDevices() {
      this.$router.push({name: 'customer-devices', params: {customerId: this.customerId}})
    },
    openInvoicePage() {
      this.$router.push({name: 'invoice-customer', params: {customerId: this.customerId}})
    },
    openDynamicDiscount() {
      this.$router.push({name: 'customer-dynamic-discount', params: {customerId: this.customerId}})
    },
    openAddCreditPage() {
      this.$router.push({name: 'customer-credit-add', params: {customerId: this.customerId}})
    },
    openCustomerPAD(item) {
      this.$router.push({name: 'customer-pad', params: {customerId: this.customerId}})
    },
    openCustomerEditPage() {
      this.$router.push({name: 'customer-edit', params: {customerId: this.customerId}})
    },
    changePassword() {
      if (!(this.passStrengthValue.caps && this.passStrengthValue.length
        && this.passStrengthValue.special && this.passStrengthValue.numbers
        && this.passStrengthValue.small)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password is not valid',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.newPassword !== this.newConfirmPassword) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Password not matched',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      this.$vs.loading()
      return this.$store.dispatch('customer/changeCustomerPassword',
        {customerId: this.customerId, password: this.newPassword, username: this.customer.username})
        .then((data) => {
          this.$vs.loading.close()
          if (data.message_type == 1) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: 'Password updated',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
            this.newConfirmPassword = ''
            this.newPassword = ''
            this.showChangePassword = false
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    async updateRemark() {
      const payload = {
        remarks: this.remarks,
        customer_id: this.customer.customer_id,
      }
      this.$vs.loading()
      const data = await this.$store.dispatch('customer/updateCustomerRemark', payload).catch((error) => {
        this.$vs.loading.close()
        this.showUpdateRemark = false
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.response.statusText,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      })
      if (data) {
        this.showUpdateRemark = false
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.fetchCustomer()
        return true
      }
      this.showUpdateRemark = false
      this.$vs.loading.close()
      this.$vs.notify({
        position: 'top-center',
        title: 'Error',
        text: 'Unknown error',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger',
      })
    },
    fetchCustomer() {
      this.$vs.loading()
      return this.$store.dispatch('customer/fetchCustomerDetails', this.customerId)
        .then((data) => {
          this.customer = data
          this.shopDetailsToPrint = this.customer.shop_details_to_print == 1;
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    activateUser() {
      let s = this.customer.is_activate == true ? 0 : 1;
      this.$vs.loading()
      return this.$store.dispatch('customer/updateUserStatus', {
        customerId: this.customerId,
        status: s
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchCustomer();
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    updateCustomerShopDetailsPrint() {
      let data = {
        customer_id: this.customerId,
        shop_details_to_print: this.shopDetailsToPrint ? 1 : 0
      }
      this.updateCustomer(data);
    },
    updateAPIStatus(reset = false) {
      let data = {
        customer_id: this.customerId,
        enable_api_access: this.customer.enable_api_access ? 0 : 1
      }
      if (reset) {
        data["reset_access_key"] = 1;
        data["user_key"] = this.customer.api_user_key;
      }
      this.$vs.loading()
      return this.$store.dispatch('customer/updateCustomerAPIStatus', data)
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.customerAPIKey = atob(data.data.api_user_access_key);
          this.fetchCustomer();
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
      this.updateCustomer(data).then(() => {
        this.fetchCustomer();
      });
    },
    updateFaceValueStatus() {
      let data = {
        customer_id: this.customerId,
        has_face_value: this.customer.has_face_value ? 0 : 1
      }
      this.$vs.loading()
      return this.$store.dispatch('customer/updateCustomerExtraAPIStatus', data)
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchCustomer();
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
      this.updateCustomer(data).then(() => {
        this.fetchCustomer();
      });
    },
    updateCustomer(data) {
      this.$vs.loading()
      return this.$store.dispatch('customer/updateCustomer', data)
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchCustomer();
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    updateMaskDiscount() {
      if(!this.customer) {
        return;
      }
      let s = this.customer.mask_seller_discount == 1 ? 0 : 1;
      this.$vs.loading()
      return this.$store.dispatch('customer/updateCustomerMaskDiscount', {
        customerId: this.customerId,
        status: s
      })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchCustomer();
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  mounted() {
    this.fetchCustomer()
  },
}
</script>

<style>
.customer-text-heading {
  color: #00000085;
}

.customer-text {
  font-size: 20px;
  font-weight: 500;
  word-break: break-all;
}

.c-center {
  margin: auto;
}

.p-type {
  padding: 0 1rem;
  font-size: 10px;
}

.p-type-select {
  padding: 0 0 0 1rem;
}

.vx-card__title > h4 {
  font-weight: bold !important;
}

.upload-btn-wrapper {
  margin: 1rem;
  position: relative;
  display: flex;
}

.upload-contents {
  border: 1px dashed #00000040;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  color: #2C2C2C;
  background-color: white;
  padding: 30px 30px;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  margin: auto;
}

.upload-btn-wrapper-denomination {
  position: absolute;
}

.upload-contents-denomination {
  padding: 5px;
}

.upload-btn-wrapper input[type=file] {
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.p-image-holder {
  position: relative;
  display: flex;
}

.p-image {
  width: 90%;
  object-fit: cover;
  max-height: 200px;
  margin: 1rem;
}

.p-delete-button {
  position: absolute;
  float: right;
  right: 0;
  margin: 0.5rem;
}

.p-image-denomination {
  width: 50%;
}
</style>
